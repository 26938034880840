<template>
  <div>
    <div class="min-vh-100">
      <b-row class="w-100 no-gutters">
        <b-col class="text-center text-lg-left">
          <h1 class="mr-sm-4">AUDIT LOG</h1>
        </b-col>
        <b-col lg="6" class="text-center text-lg-right">
          <b-button v-b-toggle.sidebar-audit class="btn-filter mr-0 mr-sm-1">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
          <b-dropdown
            id="dropdown-1"
            class="mr-2 btn-mains small-dropdown medium-dd"
            right
            no-flip
          >
            <template v-slot:button-content>
              <font-awesome-icon
                icon="ellipsis-v"
                title="filter-btn"
                class="text-white d-sm-none"
              />
              <span class="d-none d-sm-inline">ACTION</span>
            </template>
            <b-dropdown-item @click="exportData(1)"
              >Export Excel</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(2)">Export CSV</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-sidebar
        id="sidebar-audit"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterAuditSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              Clear
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                v-model="filter.startFilterDate"
                placeholder="Please select date"
                class="date-filter"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                v-model="filter.endFilterDate"
                placeholder="Please select date"
                class="date-filter"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-12">
              <p class="text-danger text-center mt-3 my-0" v-if="error">
                Please enter correct date
              </p>
            </div>
            <div class="col-6">
              <div>
                <p class="font-weight-bold my-2">Page</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="page-all"
                  checked="checked"
                  v-model="filterAllPage"
                  @change="handleCheckAll"
                />
                <label class="form-check-label" for="page-all">
                  All
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6" v-for="page in pageList" :key="page.id">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="page.id"
                  checked="checked"
                  v-model="filter.pageName"
                  :id="'page-' + page.id"
                  @change="handleCheckPage"
                />
                <label class="form-check-label" :for="'page-' + page.id">{{
                  page.name
                }}</label>
              </div>
            </div>
          </div>
          <div class="text-center mt-3">
            <button
              type="button"
              class="btn btn-main button"
              @click="handleFilterDate()"
            >
              Submit
            </button>
          </div>
        </div>
      </b-sidebar>

      <div class="bg-white-border px-4 px-sm-5 py-4 mt-4">
        <b-row class="no-gutters justify-content-between">
          <b-col md="5" class="px-0 px-lg-3 py-3 px-2 pt-lg-2">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="Username, Activity Name, Target, IP address"
                v-model="filter.searchText"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <TableAudit
          :items="items"
          :fields="fields"
          :isBusy="isBusy"
          :filter="filter"
          :rows="rows"
          @handleChangePage="pagination"
          :totalRowMessage="totalRowMessage"
          :pageOptions="pageOptions"
          @changePerPage="hanndleChangePerpage"
        />
      </div>
    </div>

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import axios from "axios";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import TableAudit from "./components/TableAudit.vue";

export default {
  name: "report",
  components: {
    ModalAlertError,
    TableAudit,
  },
  data() {
    return {
      fields: [
        // {
        //   key: "activityId",
        //   label: "Activity Id",
        //   class: "w-100px",
        // },
        {
          key: "timeStamp",
          label: "TimeStamp",
          class: "w-100px",
        },
        {
          key: "username",
          label: "Username",
          class: "w-100px",
        },
        {
          key: "page",
          label: "Page",
          class: "w-100px",
        },
        {
          key: "action",
          label: "Action",
          class: "w-100px",
        },
        // {
        //   key: "target",
        //   label: "Target",
        //   class: "w-100px",
        // },
        {
          key: "ipAddress",
          label: "IP Address",
          class: "w-100px",
        },

        {
          key: "detail",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      filter: {
        searchText: "",
        startFilterDate: "",
        endFilterDate: "",
        page: 1,
        pageSize: 10,
        pageName: [],
      },
      fileTypeId: 1,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      totalRowMessage: "",
      modalMessage: "",
      error: false,
      pageList: [],
      filterAllPage: false,
      listAllPageId: [],
    };
  },
  mounted: async function() {
    this.$isLoading = true;
    await this.getList();
    this.$isLoading = false;
    await this.getPage();
  },
  methods: {
    getList: async function() {
      this.isBusy = true;
      if (this.filterAllPage) {
        this.filter.pageName = [];
      }
      this.$refs.filterAuditSidebar.hide(true);

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/auditlog/AllList`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        // this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;

          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        } else {
          this.totalRowMessage = "";
        }
        this.isBusy = false;
        this.error = false;
      }
      if (this.filterAllPage) {
        this.filter.pageName = this.listAllPageId;
      }
    },
    async getPage() {
      this.$refs.filterAuditSidebar.hide(true);

      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/AuditLog/page`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.pageList = resData.detail;
        this.listAllPageId = this.pageList.map((element) => element.id);
      }
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getList();
    },
    handleFilterDate() {
      if (
        new Date(this.filter.endFilterDate) <
        new Date(this.filter.startFilterDate)
      ) {
        this.error = true;
        return;
      }

      this.filter.page = 1;
      this.getList();
    },
    handleCheckAll() {
      if (this.filterAllPage) {
        this.filter.pageName = this.listAllPageId;
      } else {
        this.filter.pageName = [];
      }
    },
    handleCheckPage() {
      // condition when check all page
      if (this.filterAllPage) {
        if (this.listAllPageId.length != this.filter.pageName.length)
          // ติ๊กช่องอื่นออกปุ่ม all จะไม่ติ๊กด้วย
          this.filterAllPage = false;
      }
      // if all page check -> all check
      else {
        if (this.listAllPageId.length == this.filter.pageName.length) {
          this.filterAllPage = true;
        }
      }
    },
    onClearFilter() {
      this.filter.page = 1;
      this.filter.startFilterDate = "";
      this.filter.endFilterDate = "";
      this.filter.pageName = [];
      this.error = false;
      this.getList();
    },
    exportData: async function(flagFileType) {
      this.$refs.loadingModal.show();
      let fileType = flagFileType === 2 ? ".csv" : ".xlsx";
      this.fileTypeId = flagFileType;
      axios({
        url: `${this.$baseUrl}/api/auditlog/export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: {
          fileTypeId: this.fileTypeId,
          startFilterDate: this.filter.startFilterDate,
          endFilterDate: this.filter.endFilterDate,
          page: this.filter.page,
          pageSize: this.filter.pageSize,
          searchText: this.filter.searchText,
          pageName: this.filter.pageName,
        },
      })
        .then((response) => {
          this.$refs.loadingModal.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateFile = this.$moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Report ` + "Audit" + `-` + dateFile + fileType
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.$refs.loadingModal.hide();
          this.modalMessage = "ไม่สามารถ Export ข้อมูลได้";
          this.$refs.modalAlertError.show();
        });
    },
  },
};
</script>

<style></style>
